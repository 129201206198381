import * as React from "react";
import { Link } from "gatsby";
import CookieBanner from "../components/CookieBanner";

const NotFoundPage = () => {
  return (
    <main>
      <h1>Vi fant dessverre ikke denne siden</h1>
      <Link to="/">Go home</Link>
      <CookieBanner />
    </main>
  );
};

export default NotFoundPage;
